import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { ToggleInputComponent } from "./toggle-input.component";

@NgModule({
	imports: [CommonModule, ReactiveFormsModule, MatSlideToggleModule],
	declarations: [ToggleInputComponent],
	exports: [ToggleInputComponent],
})
export class ToggleInputModule {}
