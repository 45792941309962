import { Component, Input } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { QuestionResponse } from "@e-tenant-hub/shared/rentals";

@Component({
	selector: "cp-form-template-free-answer",
	templateUrl: "./form-template-free-answer.component.html",
})
export class FormTemplateFreeAnswerComponent {
	@Input() question!: QuestionResponse;
	@Input() form!: FormGroup;
}
