<cp-common-modal-container
	#modalContainer
	[confirmButtonDisabled]="!this.form.valid && (isCarousselStep2$ | async) !== true"
	[confirmButtonLabel]="(isCarousselStep2$ | async) === true ? 'J\'ai compris' : 'Envoyer'"
	[showCloseButton]="(isCarousselStep2$ | async) !== true"
	(confirmButtonClicked)="submit()"
	title="Nouvel identifiant"
>
	<div class="grid w-full grid-flow-row-dense grid-cols-1">
		<cp-carousel #carousselCpt [config]="swiperOptions">
			@if ((isCarousselStep2$ | async) !== true) {
				<ng-container *cpCarouselSlide>
					<form [formGroup]="form">
						<div class="flex w-full flex-col items-center justify-center gap-6">
							<div class="flex w-full flex-col gap-3">
								<div class="font-futura self-stretch text-sm font-medium text-neutral-800">
									Merci de saisir les informations ci-dessous afin de changer votre identifiant :
								</div>
								<cp-common-input
									fill="empty"
									placeholder="Identifiant actuel"
									name="Identifiant actuel"
									formControlName="currentEmail"
									type="email"
								></cp-common-input>
								<cp-common-input
									fill="empty"
									placeholder="Mot de passe"
									name="Mot de passe"
									formControlName="currentPassword"
									type="password"
								></cp-common-input>
							</div>
							<div class="flex w-full flex-col gap-3">
								<cp-common-input
									fill="empty"
									placeholder="Nouvel identifiant"
									name="Nouvel identifiant"
									formControlName="newEmail"
									type="email"
								></cp-common-input>
								<cp-common-input
									fill="empty"
									placeholder="Confirmation du nouvel identifiant"
									name="Confirmation du nouvel identifiant"
									formControlName="newEmailConfirm"
									type="email"
								></cp-common-input>
							</div>
						</div>
					</form>
				</ng-container>
			}
			<ng-container *cpCarouselSlide>
				<div class="flex w-full flex-col items-center justify-center gap-6">
					<div class="font-futura self-stretch text-sm font-medium text-neutral-800">
						Votre identifiant a bien été modifié, un mail de confirmation vous a été envoyé. Vous allez être
						déconnecté de l'application.
					</div>
				</div>
			</ng-container>
		</cp-carousel>
	</div>
</cp-common-modal-container>
