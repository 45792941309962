import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit, inject } from "@angular/core";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { MatSelectChange } from "@angular/material/select";
import { SelectInputOption } from "@e-tenant-hub/client-platform/shared/ui/responsive/inputs/select-input";
import {
	SolicitationCategories,
	SolicitationFilter,
	SolicitationStatus,
	SolicitationsFilterService,
} from "@e-tenant-hub/shared/rentals";
import { PreciseDate, ScreenSizeService } from "@e-tenant-hub/shared/utils";
import { Observable, Subscription, tap } from "rxjs";

@Component({
	selector: "cp-search-solicitations-form",
	templateUrl: "./search-solicitations-form.component.html",
	styleUrl: "./search-solicitations-form.component.scss",
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchSolicitationsFormComponent implements OnInit, OnDestroy {
	@Input() showSearchFilter = true;
	@Input() showAdditionalFilters = true;
	private readonly solicitationsFilterService = inject(SolicitationsFilterService);
	private readonly formBuilder = inject(FormBuilder);
	private readonly screenSizeService = inject(ScreenSizeService);

	private dateRangeValueChangeHandlerSubscription!: Subscription;
	private filter!: SolicitationFilter;
	private lastCategoryFilterId!: string;
	private lastDateRangeFilterId!: string;
	private lastScopeFilterId!: string;
	private lastSearchTitleFilterId!: string;
	private lastStateFilterId!: string;

	currentFilters$ = this.solicitationsFilterService.onFilterChanged$;

	datesGroup = this.formBuilder.group({
		startDate: [""],
		endDate: [""],
	});
	searchForm = this.formBuilder.group({
		searchValue: [""],
		scope: [""],
		category: [""],
		state: [""],
		dates: this.datesGroup,
	});

	get dates(): FormGroup {
		return this.searchForm.get("dates") as FormGroup;
	}

	get startDate(): FormControl {
		return this.datesGroup.get("startDate") as FormControl;
	}

	get endDate(): FormControl {
		return this.datesGroup.get("endDate") as FormControl;
	}

	get category(): FormControl {
		return this.searchForm.get("category") as FormControl;
	}

	get scope(): FormControl {
		return this.searchForm.get("scope") as FormControl;
	}

	get search(): FormControl {
		return this.searchForm.get("searchValue") as FormControl;
	}

	get state(): FormControl {
		return this.searchForm.get("state") as FormControl;
	}

	scopeSelectOptions: SelectInputOption[] = [
		{
			label: "Partie Privative",
			value: "private-scope",
		},
		{
			label: "Partie Commune",
			value: "public-scope",
		},
	];

	categorySelectOptions: SelectInputOption[] = [
		{
			label: SolicitationCategories.record[SolicitationCategories.rentalManagement],
			value: SolicitationCategories.rentalManagement,
		},
		{
			label: SolicitationCategories.record[SolicitationCategories.technical],
			value: SolicitationCategories.technical,
		},
	];

	stateSelectOptions: SelectInputOption[] = [
		{
			label: SolicitationStatus.record[SolicitationStatus.requested],
			value: SolicitationStatus.requested,
		},
		{
			label: SolicitationStatus.record[SolicitationStatus.registered],
			value: SolicitationStatus.registered,
		},
		{
			label: SolicitationStatus.record[SolicitationStatus.inProgress],
			value: SolicitationStatus.inProgress,
		},
		{
			label: SolicitationStatus.record[SolicitationStatus.closed],
			value: SolicitationStatus.closed,
		},
	];
	get isSmartphone$(): Observable<boolean> {
		return this.screenSizeService.observeSmallScreen();
	}

	ngOnInit(): void {
		const filters = this.solicitationsFilterService.getFilters();
		if (filters) {
			filters.forEach((filter) => this.setFormControl(filter.value, filter.category));
		}

		this.dateRangeValueChangeHandlerSubscription = this.dates.valueChanges
			.pipe(tap(() => this.dateRangeValueChangeHandler()))
			.subscribe();
	}

	ngOnDestroy(): void {
		this.dateRangeValueChangeHandlerSubscription?.unsubscribe();
	}

	dateRangeValueChangeHandler(): void {
		this.setFilterByField("dates");
	}

	resetFilter(filter: SolicitationFilter): void {
		switch (filter.category) {
			case "search":
				this.searchForm.controls.searchValue.reset();
				break;
			case "scope":
				this.searchForm.controls.scope.reset();
				break;
			case "category":
				this.searchForm.controls.category.reset();
				break;
			case "state":
				this.searchForm.controls.state.reset();
				break;
			case "dates":
				this.searchForm.controls.dates.reset();
				break;
		}
		this.solicitationsFilterService.remove(filter.id);
	}

	setFilterByField(name: string): void {
		let filterFullName: string;
		switch (name) {
			case "search":
				if (!this.search.value || this.search.value === "") {
					this.solicitationsFilterService.remove(this.lastSearchTitleFilterId);
					return;
				}
				filterFullName = `Contient : ${this.search.value}`;

				this.filter = new SolicitationFilter({
					name: filterFullName,
					category: "search",
					value: this.search.value,
					selected: true,
				});
				this.lastSearchTitleFilterId = this.filter.id;
				break;
			case "scope": {
				if (!this.scope.value || this.scope.value === "") {
					this.solicitationsFilterService.remove(this.lastScopeFilterId);
					return;
				}
				const selectedScope = this.scope.value;

				filterFullName = `Périmètre : ${selectedScope.label}`;
				this.filter = new SolicitationFilter({
					name: filterFullName,
					category: "scope",
					value: selectedScope.value,
					selected: true,
				});
				this.lastScopeFilterId = this.filter.id;
				break;
			}
			case "category": {
				if (!this.category.value || this.category.value === "") {
					this.solicitationsFilterService.remove(this.lastCategoryFilterId);
					return;
				}
				const selectedCategory = this.category.value;

				filterFullName = `Catégorie : ${selectedCategory.label}`;
				this.filter = new SolicitationFilter({
					name: filterFullName,
					category: "category",
					value: selectedCategory.value,
					selected: true,
				});
				this.lastCategoryFilterId = this.filter.id;
				break;
			}
			case "state": {
				if (!this.state.value || this.state.value === "") {
					this.solicitationsFilterService.remove(this.lastStateFilterId);
					return;
				}
				const selectedState = this.state.value;

				filterFullName = `Statut : ${selectedState.label}`;
				this.filter = new SolicitationFilter({
					name: filterFullName,
					category: "state",
					value: selectedState.value,
					selected: true,
				});
				this.lastStateFilterId = this.filter.id;
				break;
			}
			case "dates": {
				if (
					!this.startDate.value ||
					this.startDate.value === "" ||
					!this.endDate.value ||
					this.endDate.value === ""
				) {
					this.solicitationsFilterService.remove(this.lastDateRangeFilterId);
					return;
				}

				filterFullName = `Du ${this.startDate.value.format("DD/MM/YYYY")} au ${this.endDate.value.format("DD/MM/YYYY")}`;
				this.filter = new SolicitationFilter({
					name: filterFullName,
					category: "dates",
					value: [this.startDate.value, this.endDate.value],
					selected: true,
				});
				this.lastDateRangeFilterId = this.filter.id;
				break;
			}
			default:
				return;
		}

		this.addFilter(this.filter);
	}

	onSelectChanged($event: MatSelectChange, controlName: string): void {
		if (!$event.value && this.searchForm.contains(controlName)) {
			this.searchForm.get(controlName)?.reset();
		}
		this.setFilterByField(controlName);
	}

	addFilter(filter: SolicitationFilter) {
		this.solicitationsFilterService.select(filter);
	}

	reset(): void {
		this.searchForm.reset();
		this.solicitationsFilterService.reset();
	}

	// Private functions

	private setFormControl(value: string | number | boolean | PreciseDate[] | undefined, category: string) {
		switch (category) {
			case "scope": {
				const scopeOptions = this.scopeSelectOptions.find((opt) => opt.value === value);
				this.scope.patchValue(scopeOptions);
				return;
			}
			case "category": {
				const categorySelectOption = this.categorySelectOptions.find((opt) => opt.value === value);
				this.category.patchValue(categorySelectOption);
				return;
			}
			case "state": {
				const stateSelectOption = this.stateSelectOptions.find((opt) => opt.value === value);
				this.state.patchValue(stateSelectOption);
				return;
			}
			case "dates": {
				const datesValues = value as PreciseDate[];
				this.startDate.setValue(datesValues[0]);
				this.endDate.setValue(datesValues[1]);
				return;
			}
			default:
				return;
		}
	}
}
