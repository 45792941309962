@if (data) {
	<cp-common-modal-container [title]="data.label">
		@for (detail of data.details; track detail.label) {
			<div
				class="inline-flex w-full flex-col items-start justify-start gap-1 rounded-lg border border-zinc-200 px-3 py-2"
			>
				<div class="flex h-11 flex-col items-start justify-start gap-1 self-stretch">
					<div class="font-futura self-stretch text-base font-medium text-neutral-800">
						{{ detail.label | stringCapitalize: "sentenceCase" }}
					</div>
					<div class="font-futura self-stretch text-sm font-medium text-zinc-600">Du {{ detail.period }}</div>
				</div>
			</div>
		}
	</cp-common-modal-container>
}
