import { Component, Input } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { PropositionReponse } from "@e-tenant-hub/shared/rentals";

@Component({
	selector: "cp-form-template-multiple-choice",
	templateUrl: "./form-template-multiple-choice.component.html",
})
export class FormTemplateMultipleChoiceComponent {
	@Input() propositions!: PropositionReponse[];
	@Input() form!: FormGroup;

	trackByProposition(index: number, _: PropositionReponse): number {
		return index;
	}
}
