import { Component, Input } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { RadioInputOption } from "@e-tenant-hub/client-platform/shared/ui/responsive/inputs/radio-input";
import { PropositionReponse, QuestionResponse } from "@e-tenant-hub/shared/rentals";

@Component({
	selector: "cp-form-template-single-choice",
	templateUrl: "./form-template-single-choice.component.html",
})
export class FormTemplateSingleChoiceComponent {
	@Input() question!: QuestionResponse;
	@Input() form!: FormGroup;

	getRadioOptions(propositions: PropositionReponse[]): RadioInputOption[] {
		return propositions.map((item: PropositionReponse) => {
			return {
				label: item.value,
				value: item.value,
			};
		});
	}
}
