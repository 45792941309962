import { ChangeDetectionStrategy, Component, Input } from "@angular/core";

@Component({
	selector: "cp-expansion-panel",
	templateUrl: "./expansion-panel.component.html",
	styleUrl: "./expansion-panel.component.scss",
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExpansionPanelComponent {
	@Input({ required: true }) title!: string;
}
