import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { MatSelectModule } from "@angular/material/select";
import { SelectInputComponent } from "./select-input.component";

@NgModule({
	imports: [CommonModule, ReactiveFormsModule, MatSelectModule],
	declarations: [SelectInputComponent],
	exports: [SelectInputComponent],
})
export class SelectInputModule {}
