import { ChangeDetectionStrategy, Component, EventEmitter, OnInit, Output, inject } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { CookiesAcceptance } from "@e-tenant-hub/shared/rentals";
import { CacheKeys, CacheStorageService } from "@e-tenant-hub/shared/utils";

export interface CookiesSettingsSelection {
	necessariesAccepted: boolean;
	googleAnalyticsAccepted: boolean;
}
@Component({
	selector: "cp-cookies-settings",
	templateUrl: "./cookies-settings.component.html",
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CookiesSettingsComponent implements OnInit {
	@Output() readonly btnAcceptAllClicked = new EventEmitter<void>();
	@Output() readonly btnRefuseAllClicked = new EventEmitter<void>();
	@Output() readonly btnSaveClicked = new EventEmitter<CookiesSettingsSelection>();

	private readonly cacheStorageService = inject(CacheStorageService);
	private readonly formBuilder = inject(FormBuilder);

	form!: FormGroup;

	constructor() {
		this.createForm();
	}

	ngOnInit(): void {
		const cachedCookiesAcceptance = this.cacheStorageService.retrieveCacheValue<CookiesAcceptance>(
			CacheKeys.APPROVAL_COOKIES_INFO
		);
		if (cachedCookiesAcceptance) {
			this.form.patchValue({
				refuseGoogleAnalyticsCookies: !cachedCookiesAcceptance.acceptedGA,
				refuseNecessaryCookies: !cachedCookiesAcceptance.acceptedNecessaries,
			});
		}
	}

	btnAcceptAllClick() {
		this.btnAcceptAllClicked.emit();
	}

	btnRefuseAllClick() {
		this.btnRefuseAllClicked.emit();
	}

	btnSaveClick() {
		this.btnSaveClicked.emit({
			necessariesAccepted: !this.form.value.refuseNecessaryCookies,
			googleAnalyticsAccepted: !this.form.value.refuseGoogleAnalyticsCookies,
		} as CookiesSettingsSelection);
	}

	createForm() {
		this.form = this.formBuilder.group({
			refuseGoogleAnalyticsCookies: [false],
			refuseNecessaryCookies: [false],
		});
	}
}
