import { Component, Input } from "@angular/core";

@Component({
	selector: "cp-form-template-default-start-message",
	templateUrl: "./form-template-default-start-message.component.html",
})
export class FormTemplateDefaultStartMessageComponent {
	@Input() title!: string;
	@Input() description!: string;
}
