import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { BaseCardModule } from "@e-tenant-hub/client-platform/shared/ui/responsive/cards/base-card";
import { TransparentCardComponent } from "./transparent-card.component";

@NgModule({
	imports: [CommonModule, BaseCardModule],
	declarations: [TransparentCardComponent],
	exports: [TransparentCardComponent],
})
export class TransparentCardModule {}
