<cp-common-modal-container
	#modalContainer
	[confirmButtonDisabled]="!form.valid || isComponentSubmitting"
	(confirmButtonClicked)="submit()"
	title="Assurance habitation"
	confirmButtonLabel="Envoyer"
>
	<form class="flex w-full flex-col gap-6" [formGroup]="form">
		<div class="flex flex-col gap-3">
			<div class="inline-flex items-center justify-start self-stretch">
				<div class="font-futura shrink grow basis-0 text-base font-medium text-neutral-800 sm:text-lg">
					Merci de sélectionner le lot puis charger votre attestation d'assurance :
				</div>
			</div>
			@if (getInsuranceStatusByRental$ | async; as insuranceStatus) {
				<cp-select-input
					[multiple]="true"
					[options]="insuranceStatus"
					fill="empty"
					formControlName="lotIds"
					name="Lot"
					placeholder="Lot"
				></cp-select-input>
			}
			<div class="inline-flex gap-3">
				<cp-date-picker-input
					class="w-1/2"
					[maxDate]="datePickerMaxDate"
					[minDate]="datePickerMinDate"
					fill="empty"
					formControlName="startDate"
					name="Date de début"
					placeholder="Date de début"
				>
				</cp-date-picker-input>
				<cp-date-picker-input
					class="w-1/2"
					[maxDate]="datePickerMaxDate"
					[minDate]="datePickerMinDate"
					fill="empty"
					formControlName="endDate"
					name="Date de fin"
					placeholder="Date de fin"
				>
				</cp-date-picker-input>
			</div>
			<cp-file-input
				#fileInput
				placeholder="Chargez votre attestation"
				name="Attestation"
				fill="dashed"
				formControlName="insuranceFile"
				required
			></cp-file-input>
		</div>
	</form>
</cp-common-modal-container>
