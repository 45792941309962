<div [formGroup]="form">
	<ng-container formArrayName="answers">
		@for (proposition of propositions; track trackByProposition) {
			<div class="flex flex-col" [formGroupName]="$index">
				<mat-checkbox formControlName="checked" color="primary">
					{{ proposition.value }}
				</mat-checkbox>
			</div>
		}
	</ng-container>
</div>
