import { ChangeDetectionStrategy, Component, Input, output } from "@angular/core";
import { Colors } from "@e-tenant-hub/shared/utils";

@Component({
	selector: "cp-chip",
	templateUrl: "./chip.component.html",
	styleUrl: "./chip.component.scss",
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChipComponent {
	@Input({ required: true }) label!: string;
	@Input() color: Colors = Colors.Primary;
	@Input() isRemovable = false;

	// @Outputs
	onRemoved = output<void>();

	Colors = Colors;

	handleRemoveEvent(): void {
		if (!this.isRemovable) return;

		this.onRemoved.emit();
	}
}
