import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, inject } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { PreciseDate, ScreenSizeService } from "@e-tenant-hub/shared/utils";
import { Subscription } from "rxjs";
@Component({
	selector: "cp-date-picker-range-input",
	templateUrl: "./date-picker-range-input.component.html",
	styleUrl: "./date-picker-range-input.component.scss",
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DatePickerRangeInputComponent implements OnInit, OnDestroy {
	@Input() label?: string;
	@Input() minDate?: PreciseDate;
	@Input() maxDate?: PreciseDate;
	@Input() datesForm!: FormGroup;
	@Input() fill: "solid" | "translucid" | "empty" | "dashed" = "translucid";

	private screenSizeService = inject(ScreenSizeService);
	private changeDetectorRef = inject(ChangeDetectorRef);

	isSmallScreen?: boolean;
	screenSizeSubscription?: Subscription;
	hasFocus = false;

	ngOnInit(): void {
		this.screenSizeSubscription = this.screenSizeService
			.observeSmallScreen()
			.subscribe((isSmallScreen: boolean) => {
				this.isSmallScreen = isSmallScreen;
				this.changeDetectorRef.detectChanges();
			});
	}

	ngOnDestroy(): void {
		this.screenSizeSubscription?.unsubscribe();
	}

	onFocus() {
		this.hasFocus = true;
	}

	onBlur() {
		this.hasFocus = false;
	}
}
