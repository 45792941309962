import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { CommonBackgroundComponent } from "./common-background.component";

@NgModule({
	imports: [CommonModule],
	declarations: [CommonBackgroundComponent],
	exports: [CommonBackgroundComponent],
})
export class CommonBackgroundModule {}
