import { ChangeDetectionStrategy, Component, EventEmitter, Output } from "@angular/core";

@Component({
	selector: "cp-cookies-acceptance",
	templateUrl: "./cookies-acceptance.component.html",
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CookiesAcceptanceComponent {
	@Output() readonly btnAcceptAllClicked = new EventEmitter<void>();
	@Output() readonly btnRefuseAllClicked = new EventEmitter<void>();
	@Output() readonly btnSettingsClicked = new EventEmitter<void>();

	btnAcceptAllClick() {
		this.btnAcceptAllClicked.emit();
	}

	btnRefuseAllClick() {
		this.btnRefuseAllClicked.emit();
	}

	btnSettingsClick() {
		this.btnSettingsClicked.emit();
	}
}
