<div class="inline-flex w-full flex-col items-start justify-start gap-1">
	@if (label) {
		<label
			class="font-futura text-xs font-medium text-neutral-400 sm:text-sm"
			[class.invalid]="ngControl.invalid && ngControl.touched"
			[class.focused]="hasFocus"
		>
			{{ label }}
		</label>
	}
	<mat-form-field
		[class.solid]="fill === 'solid'"
		[class.translucid]="fill === 'translucid'"
		[class.empty]="fill === 'empty'"
		[class.panel-open]="isPanelOpen"
		[class.panel-open__top]="isPanelOpenTop"
		appearance="outline"
		subscriptSizing="dynamic"
	>
		<mat-select
			class="!font-futura !text-sm !font-medium !text-zinc-600 sm:!text-base"
			[formControl]="ngControl.control"
			[multiple]="multiple"
			[placeholder]="placeholderOrLabel"
			(openedChange)="detectPanelOpening($event)"
			(selectionChange)="emitSelectionChange($event)"
			panelClass="!shadow-xl"
			disableRipple
		>
			<mat-select-trigger>
				{{ multiple ? ngControl.control.value?.[0]?.label : ngControl.control.value?.label }}
				@if ((ngControl.control.value?.length || 0) > 1) {
					<span class="text-xs opacity-75">
						(+{{ (ngControl.control.value?.length || 0) - 1 }}
						{{ ngControl.control.value?.length === 2 ? "autre" : "autres" }})
					</span>
				}
			</mat-select-trigger>
			@if (resetOptionLabel) {
				<mat-option
					class="!font-futura hover:!bg-primary/5 !min-h-0 !py-2 !text-sm !text-zinc-600 sm:!py-3 sm:!text-base"
					>{{ resetOptionLabel | titlecase }}</mat-option
				>
			}
			@for (option of options; track option.value) {
				<mat-option
					class="!font-futura hover:!bg-primary/5 !min-h-0 !py-2 !text-sm !text-zinc-600 sm:!py-3 sm:!text-base"
					[value]="option"
					>{{ option.label }}
				</mat-option>
			}
		</mat-select>
		<mat-error class="font-futura pt-2 text-xs font-medium text-rose-500 sm:text-sm">
			<!-- Generic errors -->
			@if (ngControl.hasError("required")) {
				<span class="inline-flex">Le champ "{{ placeholderOrLabel }}" est obligatoire.</span>
			}
		</mat-error>
	</mat-form-field>
</div>
