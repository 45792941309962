<cp-common-modal-container
	class="text-center"
	#modalContainer
	[title]="title"
	[showCloseButton]="false"
	[imgSrc]="isAcceptanceView ? '/assets/images/cookie-illustration.svg' : undefined"
>
	@if (isAcceptanceView) {
		<cp-cookies-acceptance
			(btnAcceptAllClicked)="handleBtnAcceptAllClick()"
			(btnRefuseAllClicked)="handleBtnRefuseAllClick()"
			(btnSettingsClicked)="handleBtnSettingsClick()"
		></cp-cookies-acceptance>
	} @else {
		<cp-cookies-settings
			(btnAcceptAllClicked)="handleBtnAcceptAllClick()"
			(btnRefuseAllClicked)="handleBtnRefuseAllClick()"
			(btnSaveClicked)="handleBtnSettingsSaveClick($event)"
		></cp-cookies-settings>
	}
</cp-common-modal-container>
