import { Injectable, inject } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { NotificationSnackBarComponent } from "./snack-bar/snack-bar.component";
import { NotificationSnackBarData } from "./snack-bar/snack-bar.data";

@Injectable({
	providedIn: "root",
})
export class NotificationsService {
	private matSnackBar = inject(MatSnackBar);

	showMessage(
		message: string,
		icon: string | null = null,
		isValidated: boolean | null = null,
		action?: (() => void) | null,
		actionLabel?: string | null,
		duration = 5000
	) {
		this.matSnackBar.openFromComponent(NotificationSnackBarComponent, {
			data: {
				message: message,
				icon: icon,
				type: "stampedIcon",
				isStampedIconValidated: isValidated,
				action: action,
				actionLabel: actionLabel,
			} as NotificationSnackBarData,
			duration: duration,
			verticalPosition: "top",
		});
	}

	showConfirmationMessage(
		message: string,
		action?: (() => void) | null,
		actionLabel?: string | null,
		duration = 5000
	) {
		this.matSnackBar.openFromComponent(NotificationSnackBarComponent, {
			data: {
				message: message,
				type: "confirmation",
				action: action,
				actionLabel: actionLabel,
			} as NotificationSnackBarData,
			duration: duration,
			verticalPosition: "top",
		});
	}

	showErrorMessage(message: string, action?: (() => void) | null, actionLabel?: string | null, duration = 5000) {
		this.matSnackBar.openFromComponent(NotificationSnackBarComponent, {
			data: {
				message: message,
				type: "error",
				action: action,
				actionLabel: actionLabel,
			} as NotificationSnackBarData,
			duration: duration,
			verticalPosition: "top",
		});
	}

	showInformationMessage(
		message: string,
		action?: (() => void) | null,
		actionLabel?: string | null,
		duration = 5000
	) {
		this.matSnackBar.openFromComponent(NotificationSnackBarComponent, {
			data: {
				message: message,
				type: "information",
				action: action,
				actionLabel: actionLabel,
			} as NotificationSnackBarData,
			duration: duration,
			verticalPosition: "top",
		});
	}
}
