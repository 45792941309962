<div class="inline-flex w-full flex-col items-start justify-start gap-1">
	@if (label) {
		<label
			class="font-futura text-xs font-medium text-neutral-400 lg:text-sm"
			[class.invalid]="ngControl.invalid && ngControl.touched"
			[class.focused]="hasFocus"
		>
			{{ label }}
		</label>
	}
	<mat-form-field
		[class.solid]="fill === 'solid'"
		[class.translucid]="fill === 'translucid'"
		[class.empty]="fill === 'empty'"
		(focus)="onFocus()"
		appearance="outline"
		subscriptSizing="dynamic"
	>
		<textarea
			class="!font-futura !text-sm !font-medium !text-zinc-600 lg:!text-base"
			#autosize="cdkTextareaAutosize"
			[ngClass]="{
				'!pointer-events-none': ngControl.control.disabled
			}"
			[formControl]="ngControl.control"
			[placeholder]="placeholderOrLabel"
			[maxLength]="maxLength ?? -1"
			[cdkAutosizeMinRows]="minRows"
			[cdkAutosizeMaxRows]="maxRows"
			cdkTextareaAutosize
			matInput
		></textarea>

		<!-- Hint -->
		@if (showHint) {
			<mat-hint class="font-futura text-xs font-medium !text-zinc-600 sm:text-sm" [align]="'end'">
				{{ ngControl.value.length }} / {{ maxLength }}
			</mat-hint>
		}

		<!-- Errors -->
		<mat-error class="font-futura pt-2 text-xs font-medium text-rose-500 lg:text-sm">
			@if (ngControl.errors) {
				<!-- Generic errors -->
				@if (ngControl.hasError("required")) {
					<span class="inline-flex">Le champ "{{ placeholderOrLabel }}" est obligatoire.</span>
				}

				<!-- Max length errors -->
				@if (ngControl.hasError("maxlength")) {
					<span>
						Le champ "{{ label }}" ne doit pas dépasser
						{{ ngControl.errors["maxlength"].requiredLength }} caractères.
					</span>
				}
			}
		</mat-error>
	</mat-form-field>
</div>
