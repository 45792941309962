<form class="grid w-full grid-flow-row-dense grid-cols-1 gap-4" [formGroup]="searchForm">
	<div class="inline-flex grow basis-0 flex-col flex-wrap items-start justify-start gap-4 self-stretch sm:flex-row">
		@if (showSearchFilter) {
			<cp-common-input
				class="grow-[2]"
				[ngClass]="{ 'w-full': (isSmartphone$ | async) }"
				[type]="'search'"
				[placeholder]="'Rechercher'"
				(focusout)="setFilterByField('search')"
				(keydown.enter)="setFilterByField('search')"
				formControlName="searchValue"
			>
			</cp-common-input>
		}
		@if (showAdditionalFilters) {
			<!-- TODO comment temporary waiting specification to display public solicitation
			<cp-select-input
				class="grow"
				[ngClass]="{ 'w-full': (isSmartphone$ | async) }"
				[fill]="(isSmartphone$ | async) ? 'empty' : 'translucid'"
				[options]="scopeSelectOptions"
				[multiple]="false"
				[resetOptionLabel]="'Tous'"
				[placeholder]="'Périmètre'"
				(selectionChanged)="onSelectChanged($event, 'scope')"
				formControlName="scope"
			></cp-select-input> -->
			<cp-select-input
				class="grow"
				[ngClass]="{ 'w-full': (isSmartphone$ | async) }"
				[fill]="(isSmartphone$ | async) ? 'empty' : 'translucid'"
				[options]="categorySelectOptions"
				[multiple]="false"
				[resetOptionLabel]="'Toutes'"
				[placeholder]="'Catégorie'"
				(selectionChanged)="onSelectChanged($event, 'category')"
				formControlName="category"
			></cp-select-input>
			<cp-select-input
				class="grow"
				[ngClass]="{ 'w-full': (isSmartphone$ | async) }"
				[fill]="(isSmartphone$ | async) ? 'empty' : 'translucid'"
				[options]="stateSelectOptions"
				[multiple]="false"
				[placeholder]="'État'"
				[resetOptionLabel]="'Tous'"
				(selectionChanged)="onSelectChanged($event, 'state')"
				formControlName="state"
			>
			</cp-select-input>
			<cp-date-picker-range-input
				class="grow"
				[ngClass]="{ 'w-full': (isSmartphone$ | async) }"
				[fill]="(isSmartphone$ | async) ? 'empty' : 'translucid'"
				[datesForm]="dates"
				formGroupName="dates"
			></cp-date-picker-range-input>
			@if (isSmartphone$ | async) {
				<cp-rounded-button
					class="grow self-center"
					[label]="'Réinitialiser'"
					[isFullWidth]="false"
					[isReversed]="true"
					[icon]="'restart_alt'"
					(clicked)="reset()"
				>
				</cp-rounded-button>
			}
		}
	</div>
	@if (currentFilters$ | async; as currentFilters) {
		<div
			class="inline-flex grow basis-0 flex-col flex-wrap items-start justify-start gap-4 self-stretch sm:flex-row"
			[ngClass]="{ hidden: (isSmartphone$ | async) }"
		>
			@for (filter of currentFilters; track $index) {
				<cp-chip [isRemovable]="true" [label]="filter.name" (onRemoved)="resetFilter(filter)"></cp-chip>
			}
		</div>
	}
</form>
