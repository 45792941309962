import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { TextareaInputComponent } from "./textarea-input.component";

@NgModule({
	imports: [CommonModule, ReactiveFormsModule, MatInputModule, MatFormFieldModule],
	declarations: [TextareaInputComponent],
	exports: [TextareaInputComponent],
})
export class TextareaInputModule {}
