import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { Colors, PreciseDate } from "@e-tenant-hub/shared/utils";

@Component({
	selector: "cp-chat-bubble",
	templateUrl: "./chat-bubble.component.html",
	styleUrl: "./chat-bubble.component.scss",
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatBubbleComponent {
	@Input() header?: string;
	@Input() content?: string;
	@Input() footer?: string;
	@Input() footerIcon?: string;
	@Input() footerColor?: Colors;
	@Input() date?: PreciseDate | null;
	@Input() position: "left" | "right" = "left";

	Colors = Colors;
}
