<div class="inline-flex w-full flex-col items-end justify-center gap-3">
	@if (label) {
		<div class="font-futura self-stretch text-sm font-medium text-zinc-600 sm:text-base">{{ label }}</div>
	}

	<mat-radio-group class="flex w-full flex-col items-start gap-3" [formControl]="ngControl.control">
		@for (option of options; track option.value) {
			<mat-radio-button
				class="font-futura w-full rounded-lg border border-zinc-200 p-2 text-sm font-medium sm:p-3 sm:text-base"
				[value]="option.value"
			>
				{{ option.label }}
			</mat-radio-button>
		}
	</mat-radio-group>
</div>
