<cp-common-modal-container
	#modalContainer
	[confirmButtonDisabled]="!form.valid || !form.dirty || isComponentSubmitting"
	(confirmButtonClicked)="submit()"
	title="Préférences"
	confirmButtonLabel="Enregistrer"
>
	<div class="flex w-full flex-col items-center justify-center">
		<form [formGroup]="form">
			<div class="flex w-full flex-col items-center justify-start gap-6">
				<div class="w-full gap-1 sm:gap-2">
					<div class="inline-flex items-center justify-start self-stretch">
						<div class="font-futura shrink grow basis-0 text-base font-medium text-neutral-800 sm:text-lg">
							Réception de l'avis d'échéance
						</div>
					</div>
					<div class="flex flex-col">
						<cp-radio-input
							[options]="noticeDeliveryModesRadioOptions"
							label="Choisissez le mode que vous préférez concernant la réception de vos avis d'échéance :"
							formControlName="noticeDeliveryMode"
						></cp-radio-input>
					</div>
				</div>
				<div class="w-full gap-1 sm:gap-2">
					<div class="inline-flex items-center justify-start self-stretch">
						<div class="font-futura shrink grow basis-0 text-base font-medium text-neutral-800 sm:text-lg">
							Mode de contact préféré
						</div>
					</div>
					<div class="flex flex-col">
						<cp-radio-input
							[options]="favoriteContactModesRadioOptions"
							label="Choisissez le mode que vous préférez concernant les échanges entre vous et Est Métropole Habitat :"
							formControlName="favoriteContactMode"
						></cp-radio-input>
					</div>
				</div>
			</div>
		</form>
	</div>
</cp-common-modal-container>
