import { ChangeDetectionStrategy, Component, OnDestroy, ViewChild, inject } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { CarouselComponent } from "@e-tenant-hub/client-platform/shared/ui/mobile/carousel";
import { NotificationsService } from "@e-tenant-hub/client-platform/shared/ui/responsive/notifications";
import { AuthService } from "@e-tenant-hub/shared/auth";
import { UpdateEmailRequest, UsersService } from "@e-tenant-hub/shared/rentals";
import { CommonValidators } from "@e-tenant-hub/shared/utils";
import { Observable, ReplaySubject, Subscription, catchError, map, merge, of } from "rxjs";
import { SwiperOptions } from "swiper/types";

@Component({
	selector: "cp-email-form-modal",
	templateUrl: "./email-form-modal.component.html",
	styleUrl: "./email-form-modal.component.scss",
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmailFormModalComponent implements OnDestroy {
	@ViewChild("carousselCpt") caroussel?: CarouselComponent;

	private readonly authService = inject(AuthService);
	private readonly formBuilder = inject(FormBuilder);
	private readonly notificationsService = inject(NotificationsService);
	private readonly usersService = inject(UsersService);
	private readonly user = this.authService.user;

	swiperOptions: SwiperOptions = {
		pagination: false,
		noSwiping: true,
		noSwipingSelector: "*",
		injectStyles: [
			`
			:host .swiper {
			  overflow: initial;
			}
			:host .swiper-pagination {
				bottom: -1.375rem;
			}
			:host .swiper-backface-hidden ::slotted(swiper-slide.swiper-slide-prev) {
				transform:translateX(-1.5rem);
			}
			:host .swiper-backface-hidden ::slotted(swiper-slide.swiper-slide-next) {
				transform:translateX(1.5rem);
			}
			`,
		],
	};

	form!: FormGroup;
	submitSubscription!: Subscription;

	private _updateResult = false;
	private _updateResultSubject = new ReplaySubject<boolean>(1);
	updateResult$: Observable<boolean> = this._updateResultSubject.asObservable();

	isCarousselStep2$: Observable<boolean> = merge(this._updateResultSubject.pipe(map(() => this._updateResult)));

	constructor() {
		this.createForm();
	}

	ngOnDestroy(): void {
		this.submitSubscription?.unsubscribe();
	}

	clearFormStatus(): void {
		this.form.markAsUntouched();
		this.form.markAsPristine();
	}

	createForm() {
		this.form = this.formBuilder.group({
			currentEmail: ["", [Validators.required, CommonValidators.email()]],
			currentPassword: ["", [Validators.required]],
			newEmail: ["", [Validators.required, CommonValidators.email()]],
			newEmailConfirm: [
				"",
				[Validators.required, CommonValidators.email(), CommonValidators.matchingValues("newEmail")],
			],
		});
	}

	submit() {
		return !this._updateResult ? this.submitStep1() : this.submitStep2();
	}

	submitStep1() {
		if (!this.form.valid) return;

		const updateRequest = {
			email: this.form.value.currentEmail,
			password: this.form.value.currentPassword,
			newEmail: this.form.value.newEmail,
		} as UpdateEmailRequest;

		this.submitSubscription = this.usersService
			.updateEmail(this.user.username, updateRequest)
			.pipe(
				catchError(() => {
					this.notificationsService.showErrorMessage(
						"Le changement d'identifiant a échoué, veuillez vérifier les informations saisies ou réessayer ultérieurement."
					);
					this._updateResult = false;
					this._updateResultSubject.next(false);
					return of(undefined);
				}),
				map((response) => {
					if (response === null) {
						this.clearFormStatus();
						this.caroussel?.slideNext();
						this._updateResult = true;
						this._updateResultSubject.next(true);
					}
				})
			)
			.subscribe();
	}

	submitStep2() {
		this.authService.logout();
	}
}
