import { ChangeDetectionStrategy, Component, Input } from "@angular/core";

@Component({
	selector: "cp-common-background",
	templateUrl: "./common-background.component.html",
	styleUrl: "./common-background.component.scss",
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommonBackgroundComponent {
	@Input() withMoreOpacity = false;
}
