<div class="inline-flex w-full flex-col items-start justify-center gap-1">
	@if (label) {
		<label
			class="font-futura text-xs font-medium text-neutral-400 lg:text-sm"
			[class.invalid]="ngControl.invalid && ngControl.touched"
			[class.focused]="hasFocus"
		>
			{{ label }}
		</label>
	}

	<div class="inline-flex w-full items-center justify-center gap-3 self-stretch">
		<div
			class="font-intro text-sm font-medium uppercase sm:text-base"
			[class.text-primary]="!isChecked"
			[class.text-zinc-600]="isChecked"
			(click)="toggleCpt.checked = false"
		>
			{{ unselectedLabel }}
		</div>
		<mat-slide-toggle
			class="flex flex-col items-start gap-3"
			#toggleCpt
			[formControl]="ngControl.control"
			[hideIcon]="true"
			color="primary"
		>
		</mat-slide-toggle>
		<div
			class="font-intro text-sm font-medium uppercase text-zinc-600 sm:text-base"
			[class.text-primary]="isChecked"
			[class.text-zinc-600]="!isChecked"
			(click)="toggleCpt.checked = true"
		>
			{{ selectedLabel }}
		</div>
	</div>
</div>
