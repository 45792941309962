import { ChangeDetectionStrategy, Component, inject } from "@angular/core";
import { MAT_SNACK_BAR_DATA } from "@angular/material/snack-bar";
import { NotificationSnackBarData } from "./snack-bar.data";

@Component({
	selector: "cp-notification-snack-bar",
	templateUrl: "./snack-bar.component.html",
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationSnackBarComponent {
	data: NotificationSnackBarData = inject(MAT_SNACK_BAR_DATA);

	icon?: string;

	constructor() {
		switch (this.data.type) {
			case "confirmation":
				this.icon = "check_circle";
				break;
			case "error":
				this.icon = "cancel";
				break;
			case "information":
				this.icon = "info";
				break;
			default:
				this.icon = this.data.icon;
		}
	}
}
