<cp-common-modal-container [title]="currentTitle">
	<div class="grid w-full grid-flow-row-dense grid-cols-1">
		<cp-carousel #carouselCpt (slideChangedEvent)="handleCarouselSlideChange($event)">
			@for (announcement of announcements; track announcement.title) {
				<ng-container *cpCarouselSlide>
					<div class="w-full flex-col items-start justify-start" [innerHTML]="announcement.htmlContent"></div>
				</ng-container>
			}
		</cp-carousel>
	</div>
</cp-common-modal-container>
