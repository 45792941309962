<cp-common-modal-container
	#modalContainer
	[confirmButtonDisabled]="!this.form.valid"
	(confirmButtonClicked)="submit()"
	title="Nouveau mot de passe"
	confirmButtonLabel="Modifier"
>
	<div class="flex w-full flex-col items-center justify-center">
		<form [formGroup]="form">
			<div class="flex w-full flex-col items-center justify-center gap-3">
				<div class="font-futura self-stretch text-sm font-medium text-neutral-800">
					Merci de saisir les informations ci-dessous afin de changer votre mot de passe :
				</div>
				<div class="flex w-full flex-col">
					<cp-common-input
						fill="empty"
						placeholder="Mot de passe actuel"
						name="Mot de passe actuel"
						formControlName="currentPassword"
						type="password"
					></cp-common-input>
				</div>
				<div class="flex w-full flex-col">
					<cp-common-input
						fill="empty"
						placeholder="Nouveau mot de passe"
						name="Nouveau mot de passe"
						formControlName="newPassword"
						type="password"
					></cp-common-input>
				</div>
				<div class="flex w-full flex-col">
					<cp-common-input
						fill="empty"
						placeholder="Confirmation du nouveau mot de passe"
						name="Confirmation du nouveau mot de passe"
						formControlName="newPasswordConfirm"
						type="password"
					></cp-common-input>
				</div>
			</div>
		</form>
	</div>
</cp-common-modal-container>
